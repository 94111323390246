<template>
  <skeleton-weekly-gauge-widget v-if="loading" />

  <div
    v-else
    class="h-72 bg-white pl-5 pr-3 pt-2 flex flex-col justify-around pb-4 mt-5 rounded-3xl shadow-2xl"
  >
    <p class="text-black text-xl">
      {{ $t(`dashboardWeaklyGauge.${responseData["widget_name"][0]}`) }}
    </p>
    <Line
      ref="lineChart"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :styles="styles"
    />
  </div>
</template>

<script>
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
} from "chart.js";
import axios from "axios";
import { mapGetters } from "vuex";
import SkeletonWeeklyGaugeWidget from "./SkeletonWeeklyGaugeWidget.vue";
import {
  jobDataInCache,
  storeJobDataInCache,
} from "../helperFunctions/DataCaching";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
);

export default {
  props: ["loadingToggle"],
  watch: {
    loadingToggle() {
      if (this.loadingToggle == true) {
        this.loading = true;
      } else if (this.loadingToggle == false) {
        this.fetchDataFromBackend(this.currentJobId);
      }
    },
  },
  components: { Line, SkeletonWeeklyGaugeWidget },
  computed: {
    ...mapGetters(["currentJobId", "authorizationHeader", "backendUrl"]),
    // GaugeOptions() {},
    chartId() {
      return {
        default: "line-chart",
      };
    },
    styles() {
      return {
        position: "relative",
        height: "230px",
      };
    },
    chartOptions() {
      return {
        scales: {
          y: {
            type: 'linear',
            position: 'left',
            beginAtZero: true,
            grid: {
              display: this.visibility.group1,
            },
            title: {
              display: this.visibility.group1,
              text: 'Contacted Counts',
              color: 'black', // Adjust the color as needed
            },
            ticks: {
              color: this.visibility.group1 ? '#7c89e7' : 'transparent', 
            },
          },
          y1: {
            type: 'linear',
            position: 'right',
            beginAtZero: true,
            grid: {
              display: true,
            },
            title: {
              display: this.visibility.group2,
              text: 'Presented to Client Counts',
              color: 'black', // Adjust the color as needed
            },
            ticks: {
              color: this.visibility.group2 ? '#28a45a' : 'transparent', 
            },
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false,
        },
        plugins: {
          legend: {
            onClick: (e, legendItem) => {
              const datasetIndex = legendItem.datasetIndex;
              if (datasetIndex < 2) {
                this.visibility.group1 = !this.visibility.group1;
              } else {
                this.visibility.group2 = !this.visibility.group2;
              }
              this.$refs.lineChart.chart.update();
            },
            labels: {
              color: 'black',
            },
          },
        },
      };
    },
    chartData() {
      let legendName = this.responseData["Legend_name"];
      let actualContactedCount = this.responseData[
        "actual_new_candidate_counts"
      ].map((value) => {
        return JSON.parse(value);
      });

      // let estimatedContactedCount = this.responseData[
      //   "estimated_new_candidate_counts"
      // ].map((value, i) => {
      //   // remove null values from actual count and then remove initial values of estimated count uptill length of actual values
      //   // logic to start estiated line after actual line
      //   let withoutNulls = this.responseData[
      //     "actual_new_candidate_counts"
      //   ].filter((val) => val !== "null");

      //   if (i < withoutNulls.length - 1) return null;
      //   else if (value < 0) return 0;
      //   else return JSON.parse(value);
      // });

      // Display all values in estimatedContactedCount without initial filtering logic
      let estimatedContactedCount = this.responseData["estimated_new_candidate_counts"].map((value) => {
        return value < 0 ? 0 : JSON.parse(value); // Keep values greater than or equal to 0
      });

      let estimatedPresentedCount = this.responseData["estimated_presented_to_client_candidate_counts"].map((value) => {
        return value < 0 ? 0 : JSON.parse(value); // Keep values greater than or equal to 0
      });

      // Feature - Hired 
      // -->[START]
      // let hiredCandidateCount = this.responseData["hired_candidate_counts"].map(value => JSON.parse(value));
      // -->[END]

      // let interviewedCandidateCount = this.responseData["interviewed_candidate_counts"].map(value => JSON.parse(value));
      let presentedToClientCandidateCount = this.responseData["presented_to_client_candidate_counts"].map(value => JSON.parse(value));

      // logic to replace first and last week name with project start and end date
      let tempLabels = [...this.responseData.weeks];
      tempLabels[0] = this.responseData["dates"][0];
      tempLabels[tempLabels.length - 1] = this.responseData["dates"][1];
      let labels = [...tempLabels, ...this.responseData["extra_weeks"]];
      return {
        labels: [...labels],
        datasets: [
          {
            label: this.$t(`dashboardWeaklyGauge.${legendName[1]}`), // Estimated Contacted
            data: this.visibility.group1 ? estimatedContactedCount : Array(estimatedContactedCount.length).fill(null), // Show/hide based on group visibility
            borderColor: "#c3dafe",
            yAxisID: 'y',
            backgroundColor: "transparent",
            borderDash: [5, 5],
            tension: 0.3,
            pointBackgroundColor: "white",
            pointBorderColor: "#c3dafe",
            radius: 5,
          },
          {
            label: this.$t(`dashboardWeaklyGauge.${legendName[0]}`), // Actual Contacted
            data: this.visibility.group1 ? actualContactedCount : Array(actualContactedCount.length).fill(null),
            borderColor: "#7c89e7",
            yAxisID: 'y',
            backgroundColor: "#7c89e7",
            tension: 0.3,
            pointBackgroundColor: "white",
            pointBorderColor: "#7c89e7",
            radius: 5,
          },
          {
            label: this.$t(`dashboardWeaklyGauge.${legendName[1]}`), // Estimated Presented
            data: this.visibility.group2 ? estimatedPresentedCount : Array(estimatedPresentedCount.length).fill(null),
            borderColor: "#6ff0b3",
            yAxisID: 'y1',
            backgroundColor: "transparent",
            borderDash: [5, 5],
            tension: 0.3,
            pointBackgroundColor: "white",
            pointBorderColor: "#6ff0b3",
            radius: 5,
          },
          {
            label: this.$t(`dashboardWeaklyGauge.${legendName[3]}`), // Actual Presented
            data: this.visibility.group2 ? presentedToClientCandidateCount : Array(presentedToClientCandidateCount.length).fill(null),
            borderColor: "#28a45a",
            yAxisID: 'y1',
            backgroundColor: "#28a45a",
            tension: 0.3,
            pointBackgroundColor: "white",
            pointBorderColor: "#28a45a",
            radius: 5,
          },
    
          // Feature - Hired 
          // -->[START]
          //   {
          //   labels: [...labels],
          //   label: this.$t(`dashboardWeaklyGauge.${legendName[2]}`),
          //   backgroundColor: "#34d399",
          //   borderColor: "#34d399",
          //   data: [...hiredCandidateCount],
          //   tension: 0.3,
          //   pointBackgroundColor: "white",
          //   pointBorderColor: "#34d399",
          //   radius: 5,
          // },
          // -->[END]
        ],
      };
    },
  },
  methods: {
    fetchDataFromBackend(jobId) {
      this.loading = true;

      const headers = this.authorizationHeader;
      axios
        .get(
          this.backendUrl +
            "/widget/new-candidate-historic-view" +
            "?jobId=" +
            jobId,
          {
            headers,
          }
        )
        .then((response) => {
          storeJobDataInCache(jobId, response.data, "WeeklyGauge");
          this.responseData = { ...response.data };
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    if (this.currentJobId !== null) {
      // fetch data from cache of current job id -
      // if exist then do not attempt API hit and get all data from cache else get data from backend
      if (jobDataInCache(this.currentJobId, "WeeklyGauge")) {
        this.responseData = {
          ...jobDataInCache(this.currentJobId, "WeeklyGauge"),
        };
        this.loading = false;
      } else {
        this.fetchDataFromBackend(this.currentJobId);
      }
    } else {
      console.log("currentJobId: " + this.currentJobId);
    }
  },
  data() {
    return {
      loading: true,
      responseData: {},
      visibility: {
        group1: true, // For estimated and actual candidates contacted counts
        group2: true, // For estimated and actual candidates presented counts
    },
    };
  },
};
</script>
